import styled from "styled-components/macro";
import { ellipsis, hideVisually } from "polished";

export const Ellipsis = styled.div`
  ${ellipsis()}
`;

export const UnstyledButton = styled.button`
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.base};
  line-height: 1;
  cursor: pointer;
`;

export const VisuallyHidden = styled.span`
  ${hideVisually}
`;
