import { navigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import { FetchPost } from "./utils";

const DeleteBox = () => {
  const { t } = useTranslation();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    FetchPost("player/action-delete-send/", { null: "null" }).then(() =>
      navigate("/player/verify-otp-delete")
    );
  };

  return (
    <Box mb={4}>
      <Alert type="transparent">
        <Copy>
          <p>
            <strong>
              {t(
                "deleteBox.sendOtp.p1",
                "Would you like to delete your account?"
              )}
            </strong>
          </p>
          <p>
            {t(
              "deleteBox.sendOtp.p2",
              "To delete your account, we need to send a code to your registered email address."
            )}
          </p>
          <p>
            {t(
              "deleteBox.sendOtp.p3",
              "Please note once you complete the deletion, there is no way of getting your account back."
            )}
          </p>
          <form onSubmit={handleSubmit}>
            <Box mt={3} mb={2}>
              <Button type="submit">
                {t("deleteBox.sendOtp.button", "Send me the deletion code")}
              </Button>
            </Box>
          </form>
        </Copy>
      </Alert>
    </Box>
  );
};

export default DeleteBox;
